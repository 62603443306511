.App {
  background-image: url('./assets/background.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  overflow: hidden;
  padding: 0;
}
.Background {
  background-color: aliceblue;
  height: 98vh;
  margin-top: 1vh;
  border-radius: 10px;
  overflow: hidden;
  padding: 0;
  padding-top: 5px;
  
}
.Scroll {
  overflow-y: scroll;
  height: 90vh;
  scrollbar-width: thin;
}

.k-pdf-export .head {
  text-align: center;
}
.table {
  border-collapse: collapse;
  
}
.k-pdf-export .table, #th, .td {
  border:1px solid #000;
  font-size: 10px;
  font-weight: normal;
  height: 20px;
  max-height: 25px;
  text-align: center;
  margin: 0;
  
  text-align: left;
  
}
.k-pdf-export .table {
  border-bottom:0;
  border-left:0;
 
  
}
.k-pdf-export  .td, #th {
  border-top:0;
  border-right:0;
}

.k-pdf-export .td {
  min-width: 40px;
  padding-left: 2px!important;
}
.k-pdf-export #sl {
  max-width: 20px!important;
  text-align:  center;
}
.k-pdf-export #description {
  min-width: 170px!important;
}
.k-pdf-export #no-border {
  border-top: 0!important;
  border-bottom: 0!important;
}
.k-pdf-export #no-border-gst {
  border-top: 0!important;
  border-bottom: 0!important;
  text-align: right!important;
  font-style: italic!important;
}

.k-pdf-export #hsn {
  display: flex;
}
.k-pdf-export #right {
  text-align: right;
  padding-right: 5px;
}
